import { useEffect, useState } from 'react'
import Link from 'next/link'
import { Button, Col, Grid, Row, Typography } from 'antd'
import { getColors } from 'services/color'
import { strapi } from 'services/strapi'
import { BLOG_ARTICLES_BY_CATEGORY } from 'etc/queries'
import { Article, ComponentCompanyColor, ComponentModulesContent, Vendor } from 'etc/strapi-types'
import BackgroundWrapper from 'components/background-wrapper'
import Spacer from 'components/spacer'
import ArticlePreview from 'components/blog/article-preview'
import styles from './content-module.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	module: ComponentModulesContent
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

const ContentModule = ({ module, vendor, colors }: Props) => {
	const {
		title,
		subtitle,
		text,
		image,
		link,
		color,
		imageAlignLeft,
		linkedArticleCategory,
		margins
	} = module

	const [articles, setArticles] = useState<Article[]>([])

	useEffect(() => {
		const getArticles = async (id: string) => {
			const { data } = await strapi.query<{ articles: Article[] }>({
				query: BLOG_ARTICLES_BY_CATEGORY,
				variables: { id }
			})
			setArticles(data.articles)
		}

		if (linkedArticleCategory?.id) {
			getArticles(linkedArticleCategory.id)
		}
	}, [linkedArticleCategory?.id])

	const { background, accent, content } = getColors(colors ?? vendor?.color ?? null, color?.scheme!)

	const screen = useBreakpoint()

	return (
		<BackgroundWrapper color={background}>
			{margins?.top && <Spacer size={screen.md ? 78 : 48} />}
			<Row align='middle' gutter={[48, 32]}>
				{image && (
					<Col span={24} lg={imageAlignLeft ? 12 : 0} className={styles.imageCol}>
						<img src={image.url} className={styles.image} />
					</Col>
				)}
				<Col span={24} lg={12} className={styles.content}>
					{subtitle && (
						<Text className={`regular-${screen.md ? 20 : 16}`} style={{ color: accent }}>
							{subtitle.toUpperCase()}
						</Text>
					)}
					{title && (
						<div className={styles.title}>
							<Text
								className={`semibold-${screen.md ? 38 : 30} grey-${content === 'light' ? 1 : 9}`}
							>
								{title}
							</Text>
						</div>
					)}
					{text && (
						<div>
							<Text className={`regular-16 grey-${content === 'light' ? 4 : 9} ${styles.text}`}>
								{text}
							</Text>
						</div>
					)}
					{articles.length > 0 && (
						<div>
							<Spacer size={32} />
							<Row justify='space-between'>
								<Col>
									<Text
										className={`semibold-14 grey-${content === 'light' ? 4 : 9} ${styles.text}`}
									>
										Neueste Blog-Artikel
									</Text>
								</Col>
								<Col>
									<Link href={`/blog/kategorie/${linkedArticleCategory?.id}`}>
										<a>
											<Text className='regular-12 link' style={{ color: accent }}>
												Alle anzeigen
											</Text>
										</a>
									</Link>
								</Col>
							</Row>
							{articles.slice(0, 2).map((article, index) => (
								<div key={index} className={styles.articlePreview}>
									<ArticlePreview article={article} type='small' light />
								</div>
							))}
							<Spacer size={16} />
						</div>
					)}
					{link && (
						<Link href={link.href}>
							<Button color={accent} type='primary' className={styles.button}>
								{link.name}
							</Button>
						</Link>
					)}
				</Col>
				{image && !imageAlignLeft && (
					<Col span={0} lg={12}>
						<img src={image.url} className={styles.image} />
					</Col>
				)}
				{margins?.bottom && <Spacer size={(screen.md ? 78 : 48) - 32} />}
			</Row>
		</BackgroundWrapper>
	)
}

export default ContentModule
