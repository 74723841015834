import { Typography } from 'antd'
import { Metafield } from 'etc/storefront-types'

const { Text } = Typography

interface Props {
	metafields: Array<Metafield | null>
}

const Delivery = ({ metafields }: Props) => {
	let days = metafields.find(metafield => metafield?.key === 'estimated_delivery_days')?.value
	let weeks = metafields.find(metafield => metafield?.key === 'estimated_delivery_weeks')?.value

	if (days?.includes('.')) days = days.replace('.0', '')

	if (weeks?.includes('.')) weeks = weeks.replace('.0', '')

	if (days)
		return (
			<Text className='regular-12 grey-8'>
				Normalerweise versandfertig in {days} {days === '1' ? 'Tag' : 'Tagen'}
			</Text>
		)

	if (weeks)
		return (
			<Text className='regular-12 grey-8'>
				Normalerweise versandfertig in {weeks} {weeks === '1' ? 'Woche' : 'Wochen'}
			</Text>
		)

	return null
}

export default Delivery
