import { MouseEvent, useRef, useState } from 'react'
import styles from './zoom-image.module.less'

interface Props {
	src: string
}

const ZoomImage = ({ src }: Props) => {
	const [posititon, setPosition] = useState<{ x: number; y: number }>()

	const wrapper = useRef<HTMLDivElement>(null)

	const onMouseMove = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
		const rect = wrapper.current?.getBoundingClientRect()

		if (!rect) return

		setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top })
	}

	return (
		<div ref={wrapper} className={styles.wrapper} onMouseMove={onMouseMove}>
			<div
				style={{ transformOrigin: `${posititon?.x}px ${posititon?.y}px` }}
				className={styles.container}
			>
				<img src={src} width={557} height={743} className={styles.image} />
			</div>
		</div>
	)
}

export default ZoomImage
