import { ComponentCompanyColor } from 'etc/strapi-types'

export const hexToRgb = (hex: string) => {
	hex = hex.replace('#', '')

	const r = parseInt(hex.substr(0, 2), 16)
	const g = parseInt(hex.substr(2, 2), 16)
	const b = parseInt(hex.substr(4, 2), 16)

	return { r, g, b }
}

export const calculateLuminance = (hex: string) => {
	const { r, g, b } = hexToRgb(hex)

	return (0.2126 * r + 0.7152 * g + 0.0772 * b) / 255
}

const backgrounds = {
	light: '#FFFFFF',
	grey: '#F5F5F5',
	dark: '#272727'
}

type Scheme = keyof typeof backgrounds | 'company'

export const getColors = (colors: Omit<ComponentCompanyColor, 'id'> | null, scheme: Scheme) => {
	if (!colors) {
		colors = {
			accent: '#1f6c04',
			accentShade: '#1f6c04',
			background: '#f5f5f5',
			backgroundShade: '#f5f5f5'
		}
	}

	const background = scheme === 'company' ? colors.background : backgrounds[scheme]

	const bgL = calculateLuminance(background)
	const accentL = calculateLuminance(colors.accent)
	const accentShadeL = calculateLuminance(colors.accentShade)

	const dif1 = Math.abs(accentL - bgL)
	const dif2 = Math.abs(accentShadeL - bgL)

	let accent: string

	if (dif1 > dif2) {
		accent = colors.accent
	} else {
		accent = colors.accentShade
	}

	const content: 'light' | 'dark' = bgL >= 0.5 ? 'dark' : 'light'

	return {
		background,
		accent,
		content
	}
}

export const getDarkerColor = (color1: string, color2: string) => {
	const l1 = calculateLuminance(color1)
	const l2 = calculateLuminance(color2)

	if (l1 < l2) {
		return color1
	} else {
		return color2
	}
}

export const getBrighterColor = (color1: string, color2: string) => {
	const l1 = calculateLuminance(color1)
	const l2 = calculateLuminance(color2)

	if (l1 > l2) {
		return color1
	} else {
		return color2
	}
}
